import { Grid, Skeleton } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { TableComponent, TitleWithBackButton } from "../../../components";
import {
  Axios,
  DEFAULT_PAGINATION_LIMIT,
  formatToThousands,
  getUserRole,
} from "../../../config";

export default function DailyReconciliation() {
  const { search } = useLocation();
  const dataParams = Object.fromEntries(new URLSearchParams(search));
  const [params, setParams] = useState(
    Object.keys(dataParams).length > 0
      ? dataParams
      : {
          search: "",
          offset: 0,
          limit: DEFAULT_PAGINATION_LIMIT,
          page: 1,
        }
  );
  const [meta, setMeta] = useState({});
  const [DataFilter, setDataFilter] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(true);
  const [rows, setRows] = useState([1, 2, 3, 4, 5]);

  const column = [
    {
      name: "Date",
      renderCell: (params) =>
        loading ? <Skeleton /> : moment(params.date).format("DD/MM/YYYY"),
    },
    {
      name: "Bank",
      renderCell: (params) => (
        <Grid
          sx={{
            "& p": {
              margin: 0,
              fontWeight: 500,
              fontSize: "12px",
              lineHeight: "16.8px",
            },
          }}
        >
          {loading ? (
            <Skeleton />
          ) : (
            <>
              {params.bank} <br /> <p>{params.bank_code}</p>
            </>
          )}
        </Grid>
      ),
    },
    {
      name: "Merchant",
      renderCell: (params) => (
        <Grid
          sx={{
            "& p": {
              margin: 0,
              fontWeight: 500,
              fontSize: "12px",
              lineHeight: "16.8px",
            },
          }}
        >
          {loading ? (
            <Skeleton />
          ) : (
            <>
              {params.merchant} <br /> <p>{params.merchant}</p>
            </>
          )}
        </Grid>
      ),
    },
    {
      name: "Product",
      renderCell: (params) => (
        <Grid
          sx={{
            "& p": {
              margin: 0,
              fontWeight: 500,
              fontSize: "12px",
              lineHeight: "16.8px",
            },
          }}
        >
          {loading ? (
            <Skeleton />
          ) : (
            <>
              {params.product} <br /> <p>{params.merchant_code}</p>
            </>
          )}
        </Grid>
      ),
    },
    {
      name: "Transaction Code",
      renderCell: (params) =>
        loading ? <Skeleton /> : params.transaction_code,
    },
    {
      name: "Trans. Count",
      renderCell: (params) =>
        loading ? <Skeleton /> : params.transction_count,
    },
    {
      name: "Mismatch Trans. Count",
      renderCell: (params) =>
        loading ? <Skeleton /> : params.mismatch_transction_count,
    },
  ];

  const getDataTable = (filter = "") => {
    setLoading(true);
    Axios.get("daily-recon" + search + `&` + "inFilter=" + filter)
      .then((res) => {
        setMeta(res.data.meta);
        setRows(res.data.data || []);
        setTimeout(() => {
          setLoading(false);
          return true;
        }, 500);
      })
      .catch((error) => {
        console.log("error", error);
        return false;
      });
  };

  const getNewData = (res) => {
    return res.data.data?.map((data) => ({
      name: data.partner_name,
      id: data.id,
      partner_code: data.partner_code,
    }));
  };

  const getNewDataForProduct = (res) => {
    return res.data.data?.map((data) => ({
      name: data.partner_name,
      id: data.id,
      partner_code: data.partner_code,
    }));
  };

  const getFilterData = async () => {
    let bank,
      merchant,
      product = null;
    await Axios.get("/partner", {
      params: {
        inFilter: "partner_type_id:(1,2)",
      },
    }).then((res) => {
      if (res.data.data) console.log("res", getNewData(res));

      bank = { name: "Bank", options: filterSame(getNewData(res)) };
    });

    await Axios.get("/partner", {
      params: { andFilter: "partner_type_id:3" },
    }).then((res) => {
      if (res.data.data) {
        merchant = { name: "Merchant", options: filterSame(getNewData(res)) };
      }
    });

    await Axios.get("/partner", {
      params: { andFilter: "partner_type_id:1" },
    }).then((res) => {
      if (res.data.data)
        product = {
          name: "Product",
          options: filterSame(getNewDataForProduct(res)),
        };
    });
    setDataFilter([bank, merchant, product]);
    setTimeout(() => {
      setLoadingFilter(false);
    }, 500);
  };

  const handleSelectFilter = async (selected) => {
    setSelectedFilter(selected);
    let mapped = await mappingFilter(selected).join(",");
    console.log("mapped", selected, ">>>> selected");
    return await getDataTable(mapped);
  };

  function mappingFilter(selected) {
    return DataFilter?.map((data) => {
      let find = selected.find((select) => data.name === select.name);

      if (find) {
        let name = `${
          data.name !== "Product" ? data.name.toLowerCase() : "principal"
        }_code`;
        let code = find.selected?.map(
          (fds) => data.options.find((dt) => fds === dt.name).partner_code
        );

        // return { name, id: id.join(',') };
        if (code.length) {
          return name + ":" + `(${code.join(",")})`;
        }
      }
    })?.filter((res) => res);
  }

  function filterSame(data) {
    return data?.filter(
      (value, index, self) =>
        index ===
        self.findIndex((t) => t.place === value.place && t.name === value.name)
    );
  }

  useEffect(() => {
    if (search) {
      getDataTable();
    }
  }, [search]);

  useEffect(() => {
    let role = getUserRole();
    setSearchParams(params);
    if (role === "FBA") getFilterData();
  }, [params]);

  return (
    <Grid
      sx={(theme) => {
        return { ...theme.customContainer };
      }}
    >
      <TitleWithBackButton title="Daily Reconciliation" hiddenBackButton />
      <Grid mt="24px">
        <TableComponent
          rows={rows}
          column={column}
          onSearchTable={(val) => setParams({ ...params, ...val })}
          onPageChange={(val) => setParams({ ...params, page: val })}
          onRowsChange={({ target }) =>
            setParams({ ...params, limit: target.value, page: 1 })
          }
          filterType={"custom"}
          selectedFilter={selectedFilter}
          setSelectedFilter={handleSelectFilter}
          onFilterTable={!loadingFilter}
          dataFilter={DataFilter}
          limit={dataParams?.limit}
          totalData={meta?.total}
          page={meta?.page}
          collapse={(data) => {
            return (
              <Grid
                sx={{
                  pt: "24px",
                  pb: "24px",
                  color: "#092540",
                  "& h3, p": {
                    margin: 0,
                  },
                  "& h3": {
                    width: "140px",
                    fontWeight: 600,
                    fontSize: "14px",
                    lineHeight: "20px",
                    marginRight: "16px",
                  },
                  "& p": {
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "20px",
                    paddingRight: "60px",
                  },
                  "& .mismatch": {
                    width: "210px",
                  },
                }}
              >
                <Grid container md={9}>
                  <Grid sx={{ borderRight: "1px solid #E4E7EB" }} item>
                    <Grid display="flex" alignItems="center">
                      <h3>Total Amount</h3>
                      <p>{`Rp. ${formatToThousands(data.total_amount)}`}</p>
                    </Grid>
                    <Grid display="flex" alignItems="center" mt="8px" mb="8px">
                      <h3>Fee</h3>
                      <p>{`Rp. ${formatToThousands(data.fee)}`}</p>
                    </Grid>
                    <Grid display="flex" alignItems="center">
                      <h3>Settlement Amount</h3>
                      <p>{`Rp. ${formatToThousands(
                        data.settlement_amount
                      )}`}</p>
                    </Grid>
                  </Grid>
                  <Grid sx={{ ml: "24px" }} item>
                    <Grid display="flex" alignItems="center">
                      <h3 className="mismatch">Mismatch Total Amount</h3>
                      <p>{`Rp. ${formatToThousands(
                        data.mismatch_total_amount
                      )}`}</p>
                    </Grid>
                    <Grid display="flex" alignItems="center" mt="8px" mb="8px">
                      <h3 className="mismatch">Mismatch Fee</h3>
                      <p>{`Rp. ${formatToThousands(data.mismatch_fee)}`}</p>
                    </Grid>
                    <Grid display="flex" alignItems="center">
                      <h3 className="mismatch">Mismatch Settlement Amount</h3>
                      <p>{`Rp. ${formatToThousands(
                        data.mismatch_settlement_amount
                      )}`}</p>
                    </Grid>
                  </Grid>
                  <Grid item></Grid>
                </Grid>
              </Grid>
            );
          }}
        />
      </Grid>
    </Grid>
  );
}
